/* Ubuntu font family */
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
/* Roboto font family */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

:root {
  --title-font: 'Ubuntu', sans-serif;
  --subtitle-font: 'Roboto', sans-serif;
  --text-font: 'Roboto', sans-serif;
  --text-color: #1c3651;
  --bg-color: #ededed;
  --main-color: #33cc99;
  --orange-color: #ff8300;
}

body {
  background-color: #fff;
  font-size: 20px;
}

html {
  background-color: #fff;
}

.react-card-flip {
  padding: 0px !important;
}

/* TABS */
.nav-tabs {
  border-bottom: none;
  justify-content: center;
}

li[class='nav-item'] > .nav-link {
  font-family: 'Baloo 2';
  border-color: #dee2e6 #dee2e6 #fff;
  width: 175px;
  height: 56px;
  margin: 48px 12px 22px 5px;
  padding: 13.5px 16.1px 11.5px 15.9px;
  border-radius: 6px;
  border: solid 2px #3c9 !important;
  background-color: #fff !important;
  text-align: center;
  color: #1c3651 !important;
  font-weight: 900;
}

li[class='nav-item'] > .nav-link.active {
  font-family: 'Baloo 2';
  border-color: #dee2e6 #dee2e6 #fff;
  width: 175px;
  height: 56px;
  margin: 48px 12px 22px 5px;
  padding: 13.5px 16.1px 11.5px 15.9px;
  border-radius: 6px;
  border: solid 2px #3c9 !important;
  background-color: #3c9 !important;
  text-align: center;
  color: white !important;
  font-weight: 900;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
  padding: 0px;
}
