@media screen and (min-width: 1900px) {
  .tribute-date h3 {
    margin-top: 40px !important;
    margin-right: 40px;
  }
  #instituciones-apoyadas {
    width: 20px;
    height: 20px;
    background-color: transparent;
    margin-top: -150px;
    position: absolute;
  }
}
@media (min-width: 1100px) and (max-width: 1200px) {
  label.btn.btn-secondary {
    font-size: 12px !important;
  }
}
@media (min-width: 1024px) and (max-width: 1466px) {
  .container-work-model .row {
    padding: 0 !important;
  }
  #instituciones-apoyadas {
    width: 20px;
    height: 20px;
    background-color: transparent;
    margin-top: -150px;
    position: absolute;
  }
  .titles span {
    margin-top: 6.5%;
  }

  .about .mision-vision .mision {
    width: 380px;
    padding-right: 40px;
  }
  .about .mision-vision .vision {
    margin-right: 64px;
    width: 380px;
    padding-right: 40px;
  }

  .requisitos-title h3 {
    padding-left: 40px;
  }

  .reinvent-card p {
    margin-top: 20px;
    font-size: 20px;
  }

  .reinvent-card .reinvent-description p {
    font-size: 15px;
  }

  .modal img {
    margin-top: -20%;
  }

  .metrics_places h3 {
    font-size: 28px;
  }
  .metrics_places span {
    font-size: 15px;
  }

  .metrics_places .metrics_title {
    font-size: 18px;
  }

  .metrics_places p {
    font-size: 15px;
  }

  .ourmetrics-map-img .img-fluid {
    max-width: 120%;
  }

  .list-team-members li .member-title {
    font-size: 16px;
  }
  .list-team-members li .member-title-hover {
    font-size: 14px;
  }

  .list-team-members li img {
    width: 150px;
    height: 150px;
  }

  .process-buttons .btn-group {
    padding: 0 130px;
  }
  .reinvent_container-style .soluciones_ .general_description {
    padding: 0 70px;
  }
  .reinvent-card_container {
    padding: 0 90px;
  }
  .alianza {
    margin-top: -130px;
    margin-bottom: -130px;
  }
  .alianza img {
    width: 200px;
  }
  .softteklogo {
    margin-left: 0px;
    /* margin-top: 50px; */
  }

  .donadora {
    margin-left: 0px;
    margin-top: -20px;
  }
  .microsoft {
    margin-left: 0px;
    /* margin-bottom: 50px; */
  }
}

@media (min-width: 480px) and (max-width: 1023px) {
  .ods-container p {
    padding: 0 95px;
  }
  .ods-container .img-obj img {
    padding: 0 100px;
  }
  .requisitos-title h3 {
    padding: 0 130px;
  }
  .requirements {
    display: inline-block;
  }
  .impact-container hr {
    display: none;
  }

  .reinvent_container-style .soluciones_ .general_description {
    padding: 0 70px;
  }
  .reinvent-card p {
    margin-top: 30px;
    font-size: 20px;
  }
  .reinvent-card .reinvent-description p {
    font-size: 15px;
  }
  article.col-md-3.col-sm-6.col-xs-12.aos-init.aos-animate {
    width: 100%;
  }

  .process-buttons .btn-group {
    padding: 0 133px;
  }
  .process-buttons .btn-group label {
    font-size: 14px;
    padding: 0;
  }

  .contentreport h2 {
    top: 30%;
    font-size: 25pt;
  }
  .contentreport p {
    top: 25%;
    font-size: 20pt;
  }

  .titles h3 {
    font-size: 60px;
    -webkit-text-stroke: 2px;
  }

  .titles span {
    margin-top: 27px !important;
    font-size: 20px;
  }
  .softteklogo {
    margin-left: 0px;
    margin-top: 50px;
  }

  .donadora {
    margin-left: 0px;
  }
  .microsoft {
    margin-left: 0px;
    margin-bottom: 50px;
  }

  .mision {
    margin-bottom: 30px !important;
  }
  .about .mision-vision .mision,
  .about .mision-vision .vision {
    margin-left: 7% !important;
    margin: 0;
  }
  .list-team-members li .member-title {
    font-size: 14px;
  }
  .list-team-members li .member-title-hover {
    font-size: 12px;
  }
  .volunteer_container {
    display: inline-block;
    padding: 0;
  }
  .volunteers_dscp .dscp {
    padding: 0 150px;
  }
  .volunteers_dscp .gcs {
    padding: 0 150px;
  }
  .container-work-model hr {
    display: none;
  }
  .solutions-description .col-sm-6 {
    width: 100%;
  }
  .solutions-description h3 {
    font-size: 40px;
  }
  .solutions-description p {
    font-size: 20px;
  }
  canvas.tsparticles-canvas-el {
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .reinvent-card {
    width: 80%;
    margin-bottom: 50px;
  }
  .metrics_places h3 {
    font-size: 20px;
  }
  .metrics_places span {
    font-size: 12px;
  }
  .metrics_places .metrics_title {
    font-size: 12px;
  }
  .metrics_places h3 {
    font-size: 18px;
    padding-right: 10px;
  }
  .metrics_places p {
    font-size: 12px;
  }
  .metric-buttons {
    margin-left: 30%;
  }
  .success_about .dashboard p {
    padding: 0;
    font-size: 12px !important;
  }

  .tribute-description p {
    font-size: 12px !important;
    padding: 0 40px;
    margin-left: 54px;
    margin-top: 0px !important;
  }
  .tribute-date {
    padding: 0 30px;
    margin-right: 20px;
    font-size: 34px !important;
  }
  .tribute-date h3 {
    margin-top: 40px !important;
    margin-left: 50px;
  }
  .tribute-title h3 {
    padding: 0 50px;
    margin-bottom: 20px !important;
    font-size: 34px !important;
  }
  img[alt~='alianza'] {
    width: 75% !important;
    margin-top: 117px !important;
  }
  img[alt~='viraventos'] {
    width: 90% !important;
    margin-top: 115px !important;
  }
  img[alt~='canica'] {
    width: 90% !important;
    margin-left: 0% !important;
    margin-top: 92px !important;
  }
  img[alt~='acuerdos'] {
    width: 90% !important;
    margin-top: 100px !important;
    margin-left: 0% !important;
  }
  img[alt~='fundacion'] {
    width: 90% !important;
    margin-top: 85px !important;
  }
  img[alt~='clubninos'] {
    width: 90% !important;
    margin-top: 125px !important;
  }
  img[alt~='cruzrosa'] {
    width: 90% !important;
    margin-top: 100px !important;
  }

  img[alt~='clubninos'] {
    width: 100% !important;
    margin-left: 10% !important;
    margin-top: 120px !important;
  }
  img[alt~='t'] {
    width: 80% !important;
    margin-left: 6% !important;
    margin-top: 86px !important;
  }
  img[alt~='fundemex'] {
    width: 90% !important;
    margin-top: 100px !important;
  }
  img[alt~='villas'] {
    width: 90% !important;
    margin-top: 108px !important;
  }
  img[alt~='anspac'] {
    width: 80% !important;
    margin-top: 118px !important;
  }
  img[alt~='educo'] {
    width: 80% !important;
    margin-top: 108px !important;
  }
  img[alt~='renace'] {
    width: 80% !important;
    margin-top: 95px !important;
  }
  .slick-dots {
    bottom: 30px !important;
  }

  .navbar-nav a {
    color: black !important;
  }
  .metric-buttons a {
    margin-top: 0px;
  }
  .metric-buttons p {
    margin-top: 0px;
  }
  .metric-buttons {
    margin-left: 44% !important;
  }
}
