/*=== MEDIA QUERY ===*/
/* Textos  */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;800;900&display=swap');
/* Titulares */
@import url('https://fonts.googleapis.com/css2?family=Baloo+Da+2:wght@400;500;600;700;800&display=swap');

html {
  background-color: var(--bg-color);
  overflow-x: hidden;
  /* Rotate the content container */    
  /* transform: rotate(-90deg); */
  /* transform-origin: left top; */
  /* Set content width to viewport height */    
  width: 100vh;
  /* Set content height to viewport width */    
  height: 100vw;
  position: absolute;
  /* top: 100%; */
  left: 0;
}
body {
  background-color: black !important;
}

h1 {
  font-weight: 400;
  font-family: var(--title-font);
  color: rgb(43, 43, 43);
}

* a {
  font-family: var(--text-font);
}

.description {
  font-size: 16px;
}

#myChart,
#barChart {
  padding: 0 50px;
}

#barChart {
  margin-top: 50px;
}

/*=================================================================
  Basic Setup
==================================================================*/

::-moz-selection {
  /* Code for Firefox */
  color: #fff;
  background: #57cbcc;
}

::selection {
  color: #fff;
  background: #57cbcc;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

figure {
  margin: 0;
}

a {
  color: #fff;
  transition: all 0.3s ease-in 0s;
}

iframe {
  border: 0;
}

a,
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
}

a:focus,
a:hover {
  color: #6cb670;
}

.kill-margin-bottom {
  margin-bottom: 0 !important;
}

.padding-0 {
  padding: 0 !important;
}

.btn {
  background-color: transparent;
  color: #fff;
  padding: 10px 30px;
  border-radius: 0;
  transition: all 0.3s ease-in 0s;
}

.btn:focus {
  color: #ddd;
}

.btn-transparent {
  border: 1px solid #4e595f;
}

.btn-transparent:hover,
.btn-transparent:focus {
  background-color: #57cbcc;
  border: 1px solid transparent;
  color: #fff;
}

.form-control {
  background-color: #2f383d;
  border-radius: 0;
  padding: 5px 10px;
  border: 0 none;
  color: #ddd;
}

.form-control:focus {
  box-shadow: 0 0 0;
}

.form-control::-webkit-input-placeholder {
  /*  Chrome, Safari, Opera  */
  color: #ddd;
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #ddd;
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #ddd;
}

.form-control:-ms-input-placeholder {
  /*  Internet Explorer  */
  color: #ddd;
}

.border {
  border-top: 2px solid rgba(236, 239, 241, 0.07) !important;
  height: 1px;
  margin: 15px auto 0;
  position: relative;
  width: 30%;
}

.border:before {
  background-color: #fff;
  content: '';
  height: 6px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  top: -4px;
  width: 50px;
  background-color: #33cc99;
}

.color {
  color: var(--text-color);
}

.sub-title {
  padding: 0 0 50px;
}

.sub-title > h3 {
  border-left: 3px solid #ddd;
  border-right: 3px solid #ddd;
  display: inline-block;
  padding: 5px 25px;
  text-transform: uppercase;
}

.btn.active:focus,
.btn:active:focus,
.btn:focus {
  outline: 0;
}

.bg-one {
  background-color: #fff;
}

.parallax-section {
  background-attachment: fixed !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.no-padding {
  padding: 0 !important;
}

.inline-block {
  display: inline-block;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

figure {
  margin: 0;
}

a {
  transition: all 0.2s ease-in 0s;
}

iframe {
  border: 0;
}

a,
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
}

a:focus,
a:hover {
  color: #57cbcc;
}

.btn-main {
  background: #57cbcc;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 10px 35px;
  text-transform: uppercase;
}

.btn-main:hover {
  background: #2c9091;
  color: #fff;
}

.mt-20 {
  margin-top: 20px;
}

.section {
  padding: 100px 0;
}

.section-sm {
  padding: 70px 0;
}

.section-xs {
  padding: 50px 0;
}

.btn:focus {
  color: #ddd;
}

#home {
  position: relative;
}

.btn.active:focus,
.btn:active:focus,
.btn:focus {
  outline: 0;
}

.parallax-section {
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.preloader {
  background: #fff;
  height: 100%;
  left: 0;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sk-cube-grid {
  width: 40px;
  height: 40px;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #57cbcc;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

.title {
  padding-bottom: 60px;
}

.title h2 {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 38px;
  color: #000;
}

.title h2 span {
  color: #57cbcc;
}

.title p {
  color: #666;
}

.title.title-white h2 {
  color: #fff;
}

.bg-gray {
  background: #f9f9f9;
}

.section-bg {
  background: #fff;
}

/* .overly {
    position: relative;
}

.overly:before {
    content: '';
    background: rgba(0, 0, 0, 0.096);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
} */

.header-bradcrumb {
  background: transparent;
}

.header-bradcrumb a,
.header-bradcrumb .active {
  color: #cfcfcf;
  font-weight: 200;
  font-family: 'Quattrocento Sans', sans-serif;
}

.header-bradcrumb a:hover {
  color: #57cbcc;
}

.slick-slide {
  outline: none;
}

/* CSS mfp-with-zoom class */

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.pagination li {
  margin: 5px;
  display: inline-block;
}

.pagination li a {
  background: #242930;
  border: 0;
  color: #fff;
}

.pagination li a:hover,
.pagination li a.active {
  background: #57cbcc;
  color: #fff;
}

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.logo {
  margin-top: 10px;
}

.single-page-header {
  background-size: cover;
  padding: 140px 0 70px;
  text-align: center;
  color: #fff;
  position: relative;
}

.single-page-header:before {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

/*=================================================================
  About us section misión visión
==================================================================*/
.blocksz {
  height: 450px;
}

.blocksz h3 {
  font-size: 24px !important;
  color: #666;
}

.about {
  margin-top: -60px;
}

.about .container {
  width: 79%;
}

.about .block {
  background: #ededed;
  padding: 30px;
  border-bottom: 2px solid transparent;
  transition: 0.5s all;
  margin-bottom: 20px;
}

.about .block:hover {
  border-bottom: 2px solid #57cbcc;
}

.about .block:hover .icon-box {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.about .block .icon-box {
  position: relative;
  width: 160px;
  height: 92.38px;
  background-color: transparent;
  margin: 46.19px auto 20px;
  padding: 20px 0;
  border-left: 2px solid rgba(236, 239, 241, 0.07);
  border-right: 2px solid rgba(236, 239, 241, 0.07);
  font-size: 50px;
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.about .block .icon-box:after,
.about .block .icon-box:before {
  content: '';
  position: absolute;
  z-index: 1;
  width: 113.14px;
  height: 113.14px;
  background-color: inherit;
  left: 20.4315px;
  -webkit-transform: scaleY(0.5774) rotate(-45deg);
  transform: scaleY(0.5774) rotate(-45deg);
}

.about .block .icon-box:before {
  top: -56.5685px;
  border-top: 2px solid rgba(236, 239, 241, 0.07);
  border-right: 2px solid rgba(236, 239, 241, 0.07);
}

.about .block .icon-box:after {
  bottom: -56.5685px;
  border-bottom: 2px solid rgba(236, 239, 241, 0.07);
  border-left: 2px solid rgba(236, 239, 241, 0.07);
}

.about .block .icon-box h3 {
  color: #afbac4;
}

.about .row {
  padding: 0;
}

.beneficiados .row {
  padding: 0;
}

.beneficiados .block {
  color: #666;
  margin-top: 100px;
}

.beneficiados .block p {
  text-align: justify;
}

.beneficiados .block h2 {
  font-size: 30px !important;
  margin-bottom: 70px;
  text-transform: uppercase;
  color: #666;
}

.beneficiados .block h1 {
  font-size: 60px !important;
  font-weight: bolder;
  color: rgb(8, 185, 197);
}

.beneficiados .block span {
  font-size: 30px;
  font-weight: bolder;
}

#mainpro {
  margin-left: 34%;
}

.box {
  width: 50px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
}

.letter {
  font-size: 2cm;
  line-height: 100px;
  position: relative;
  left: -160;
  color: var(--text-color);
  transition: all 0.25s linear;
  font-family: var(--title-font);
}

.color-sub {
  color: var(--main-color);
}
/*=================================================================
About us 2 section
==================================================================*/

.about-2 .content-block {
  padding: 40px;
}

.about-2 .content-block .media {
  margin-top: 25px;
}

.about-2 .content-block .media .pull-left {
  padding-right: 15px;
}

.about-2 .content-block .media i {
  font-size: 30px;
  color: #57cbcc;
}

.about-2 .content-block .media h4 {
  font-size: 16px;
  font-weight: 600;
}

/*=================================================================
 Call To Action 1
==================================================================*/

.call-to-action {
  background-attachment: fixed;
}

.call-to-action h2 {
  font-weight: 700;
  color: #444;
}

.call-to-action p {
  font-size: 36px;
  color: #666;
}

.call-to-action .btn-main {
  margin-top: 20px;
}

/*=================================================================
 Call To Action 2
==================================================================*/

.call-to-action-2 h2 {
  line-height: 1.5;
}

.call-to-action-2 p {
  color: #fff;
}

.call-to-action-2 .btn-main {
  margin-top: 20px;
}

.contact-us {
  padding-top: 50px;
}

.contact-form {
  margin-bottom: 60px;
}

.contact-form .form-control {
  background-color: transparent;
  border: 1px solid rgba(236, 239, 241, 0.07);
  height: 45px;
}

.contact-form textarea.form-control {
  padding: 10px;
  height: 120px;
}

.contact-form input:hover,
.contact-form textarea:hover,
#contact-submit:hover {
  border-color: #57cbcc;
}

#contact-submit {
  border: 1px solid rgba(236, 239, 241, 0.07);
  background: #57cbcc;
  padding: 12px 0;
  width: 100%;
  margin: 0;
}

.contact-info p {
  margin-bottom: 25px;
}

.con-info {
  margin-bottom: 20px;
}

.con-info i,
.con-info span {
  float: left;
}

.con-info span {
  margin: -5px 0 0 15px;
}

.error {
  display: none;
  padding: 10px;
  color: #d8000c;
  border-radius: 4px;
  font-size: 13px;
  background-color: #ffbaba;
}

.success_about .dashboard {
  display: flex;
  width: 100%;
}
.success_about .dashboard button {
  width: 50%;
  background-color: transparent;
  border: none;
}

.success_about .dashboard p {
  font-family: Ubuntu;
  font-size: 18px;
  text-align: center;
  color: #1c3651;
  margin-top: 10px;
  margin-bottom: -10px;
}

.success {
  background-color: #6cb670;
  border-radius: 4px;
  color: #fff;
  display: none;
  font-size: 13px;
  padding: 10px;
}

/*=================================================================
  Item counter section
==================================================================*/

.counters-item {
  padding: 25px 0;
  margin-bottom: 10px;
  background: #292f36;
}

.counters-item i {
  margin-bottom: 10px;
  font-size: 40px;
  display: inline-block;
}

.counters-item span {
  display: block;
  font-weight: bold;
  font-size: 35px;
  line-height: 50px;
}

.counters-item h3 {
  font-size: 20px;
  margin: 0;
}

/*=================================================================
  Pricing section
==================================================================*/

.price-item {
  border: 1px solid rgba(236, 239, 241, 0.07);
  padding-bottom: 30px;
  margin-bottom: 15px;
}

.price-title {
  background-color: #292f36;
  padding: 30px 0 20px;
}

.price-title h3 {
  color: #57cbcc;
  margin: 0 0 10px;
  text-transform: uppercase;
}

.price-title p {
  font-size: 18px;
  font-weight: 400;
}

.price-title .value {
  color: #57cbcc;
  font-size: 30px;
}

.pricing li {
  padding: 10px 0;
}

.pricing li:last-child {
  border-bottom: 1px solid rgba(236, 239, 241, 0.07);
  margin-bottom: 20px;
}

.pricing li:nth-child(2n) {
  background-color: #292f36;
}

.pricing li .btn {
  text-transform: uppercase;
  margin: 20px 0;
}

#pricing {
  align-items: flex-end;
}

/*=================================================================
  Portfolio section
==================================================================*/

.portfolio-filter {
  margin-bottom: 40px;
  text-align: center;
}

.portfolio-filter button {
  border: none;
  outline: none;
  border: 1px solid rgba(236, 239, 241, 0.07);
  background: transparent;
  display: inline-block;
  font-size: 16px;
  padding: 6px 20px;
  font-weight: 700;
  color: #737f8a;
  transition: 0.3s all;
}

.portfolio-filter button.active {
  border: 1px solid #57cbcc;
  color: #57cbcc;
}

.portfolio-items-wrapper .mix {
  padding: 5px;
}

.portfolio-block {
  position: relative;
  margin-bottom: 20px;
}

.portfolio-block:before {
  transition: all 0.3s ease-in-out;
  background: white;
  opacity: 0;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  position: absolute;
  content: '';
}

.portfolio-block img {
  width: 100%;
  height: auto;
}

.portfolio-block:hover:before {
  opacity: 1;
}

.portfolio-block:hover .caption h4 {
  top: 45%;
}

.portfolio-block:hover .caption {
  opacity: 1;
  top: 55%;
}

.portfolio-block .caption {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 9;
  transition: all 0.2s ease-in-out;
  text-align: center;
}

.portfolio-block .caption h4 {
  font-size: 16px;
  font-weight: 600;
}

.portfolio-block .caption h4 a {
  color: #555;
}

.portfolio-block .caption .search-icon {
  background: #57cbcc;
  width: 50px;
  height: 50px;
  color: #fff;
  display: inline-block;
  font-size: 20px;
  border-radius: 30px;
}

.portfolio-block .caption .search-icon i {
  line-height: 50px;
}

/*=================================================================
  Services section
==================================================================*/

.service-block {
  background-color: #f6f8f7;
  padding: 60px 20px;
  border-left: 1px solid rgba(236, 239, 241, 0.07);
  border-top: 1px solid rgba(236, 239, 241, 0.07);
  margin-bottom: 40px;
  box-shadow: 2px 2px 5px rgba(44, 44, 44, 0.3), 13px 13px 0 rgba(255, 255, 255, 0.7);
  height: 350px;
}

.service-block .service-icon {
  margin-bottom: 10px;
  font-size: 50px;
}

.service-block h3 {
  text-transform: uppercase;
  font-size: 22px !important;
  margin-top: 30px;
}

/*=================================================================
  Services section 2
==================================================================*/

.service-2 .service-item {
  border: 1px solid #eee;
  margin-bottom: 30px;
  padding: 50px 20px;
  transition: all 0.3s ease 0s;
}

.service-2 .service-item:hover {
  box-shadow: 0 5px 65px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 5px 65px 0 rgba(0, 0, 0, 0.15);
}

.service-2 .service-item:hover i {
  background: #fff;
  color: #57cbcc;
}

.service-2 .service-item i {
  font-size: 30px;
  display: inline-block;
  background: #57cbcc none repeat scroll 0 0;
  border-radius: 30px;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  height: 55px;
  line-height: 55px;
  margin-bottom: 20px;
  width: 55px;
  transition: all 0.3s ease 0s;
}

/*=================================================================
  Our skills
==================================================================*/

.skill-bar p {
  font-size: 18px;
  margin-bottom: 0;
}

.skill-bar p span {
  font-style: italic;
  font-size: 30px;
  color: rgba(0, 0, 0, 0.34);
  display: inline-block;
  vertical-align: middle;
}

.skill-bar .progress {
  height: 6px;
  border-radius: 0;
}

.skill-bar .progress .progress-bar {
  background: #57cbcc;
}

/*=================================================================
  Our Team
==================================================================*/

.team-member {
  background-color: #fff;
  margin-bottom: 10px;
}

.team-member:hover .mask {
  cursor: pointer;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: rotateY(0deg) scale(1, 1);
  transform: rotateY(0deg) scale(1, 1);
}

.member-photo {
  overflow: hidden;
  position: relative;
}

.member-photo:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.member-photo img {
  transition: all 0.4s ease 0s;
}

.member-photo .mask {
  background-color: rgba(87, 203, 204, 0.7);
  position: absolute;
  text-align: center;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: all 0.5s ease-out 0s;
  -webkit-transform: rotateY(180deg) scale(0.5, 0.5);
  transform: rotateY(180deg) scale(0.5, 0.5);
}

.member-photo .mask ul {
  text-align: center;
  position: relative;
  top: 125px;
}

.member-photo .mask ul li a {
  border: 1px solid #fff;
  color: #fff;
  display: block;
  font-size: 20px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  width: 35px;
}

.member-photo .mask ul li a:hover {
  background-color: #fff;
  color: #57cbcc;
  border: 1px solid transparent;
}

.member-meta {
  padding: 15px;
}

.member-meta h4 {
  margin: 0 0 5px;
}

.member-meta span {
  font-size: 12px;
}

.voluntarios {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 50px;
}

.instituciones {
  background-color: #fff;
  padding: 60px 20px;
  border-left: 1px solid rgba(236, 239, 241, 0.07);
  border-top: 1px solid rgba(236, 239, 241, 0.07);
  margin-bottom: 40px;
  box-shadow: 2px 2px 5px rgba(44, 44, 44, 0.3), 13px 13px 0 rgba(255, 255, 255, 0.7);
}

.alianza {
  height: 350px;
  width: 100%;
  white-space: nowrap;
  text-align: center;
}

.donadora {
  padding-top: 75px;
  margin-left: 100px;
}
.microsoft {
  margin-left: 50px;
}
.img-alianza {
  width: 300px;
  height: auto;
  vertical-align: middle;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.administrable .title {
  text-align: center;
  margin-bottom: -30px;
  font-size: 11pt !important;
  font-weight: bold;
}
.administrable p {
  text-align: left;
  font-size: 12pt;
}
.administrables {
  height: 500px;
  background-color: #fff;
  padding: 60px 20px;
  border-left: 1px solid rgba(236, 239, 241, 0.07);
  border-top: 1px solid rgba(236, 239, 241, 0.07);
  margin-bottom: 40px;
  box-shadow: 2px 2px 5px rgba(44, 44, 44, 0.3), 13px 13px 0 rgba(255, 255, 255, 0.1);
}

.administrables .title {
  color: var(--main-color);
}

.administrables img {
  height: 160px;
  width: auto;
  overflow: hidden;
  margin-bottom: 30px;
}

/* family font inversion */

@import url('https://fonts.googleapis.com/css?family=Montserrat');
.inversion {
  margin-left: 50%;
  margin-top: 70px;
  font-size: 30px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.inversion div h3 {
  font-size: 30px;
  margin-bottom: 10px;
}

.inversion div p {
  font-size: 20px;
  text-align: left;
  color: #666;
}

.nombre-voluntario {
  margin-top: -40px;
}
/*=================================================================
  PROCESO
==================================================================*/

.proceso {
  padding: 0 100px;
  font-size: 16px;
}

.procesoimg img {
  display: block;
  margin: auto;
  width: 40%;
  height: auto;
}

.row {
  padding: 0 42px 0 40px;
}

/*  */
.soluciones {
  margin-top: 30px;
}

.portfolio {
  background: white;
  margin-bottom: -50px;
}

.administrable {
  background: #ededed;
}

/* INSTITUCIONES ESTILOS */
.instituciones_p {
  margin-top: 30px;
}

.instituciones {
  height: 200px;
}

.instituciones img {
  max-width: 100%;
  max-height: 100%;
  width: auto;

  display: block;
  margin: 0 auto;
}

.inst img {
  width: 90px;
  height: auto;
}

#club img {
  width: 200px;
  height: auto;
  padding-top: 15px;
}

/* TRANSITION SCROLL OUT TITLE SUBTITLE */

.scrollcontent {
  max-width: 100%;
}

.scrollcontent h2 {
  position: relative;
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: 3em;
  transition: 0.5s;
  transform-origin: left;
  transform: scaleX(1);
}

.scrollcontent h2[data-scroll='in'] {
  transform: scaleX(1);
}

.scrollcontent h2:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--main-color);
  transform-origin: right;
  transition: 0.5s;
}

.scrollcontent h2[data-scroll='in']:before {
  transform: scaleX(0);
  transition-delay: 0.25s;
}

.scrollcontent h3 {
  position: relative;
  margin: 5px 0 0;
  padding: 0;
  display: inline-block;
  font-size: 2em;
  transition: 0.5s;
  transform-origin: right;
  transform: scaleX(0);
}

.scrollcontent h3[data-scroll='in'] {
  transform: scaleX(1);
  transition-delay: 0.25s;
}

.scrollcontent h3:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--main-color);
  transform-origin: left;
  transition: 0.25s;
}

.scrollcontent h3[data-scroll='in']:before {
  transform: scaleX(0);
  transition-delay: 0.5s;
}

.scrollcontent p {
  font-size: 1.2em;
  transition: 1s;
}

/* VIDEO FROM BANNER AND TEXT */

/* OnScroll */

[data-scroll] {
  transition: opacity 2s;
}
[data-scroll='in'] {
  opacity: 1;
  margin-bottom: 0px;
}
[data-scroll='out'] {
  opacity: 0;
  margin-bottom: 50px;
}

/*=================================================================
  ODS OBJETIVOS DEL DESARROLLO SOSTENIBLE
==================================================================*/

.list-ods {
  max-width: 80%;
  padding: 0px;
  margin: 0px auto;
}
.list-ods li {
  padding: 0px;
  margin: 20px;
  text-align: center;
  list-style-type: none;
  display: inline-block;
  box-shadow: 2px 2px 5px rgba(44, 44, 44, 0.3), 13px 13px 0 rgba(255, 255, 255, 0.7);
}

.list-ods li img {
  object-fit: cover;
  object-position: center center;
  height: 100px;
  width: 100px;
  /* border-radius: 50%; */
  /* transition-duration: 0.3s; */
}

/*=================================================================
  MAP 
==================================================================*/
.svg-map {
  stroke-width: 0.3px !important;
  width: 70% !important;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.svg-map__location {
  fill: #ededed !important;
}

#mx {
  fill: var(--text-color) !important;
}

#br,
#us,
#es {
  fill: var(--main-color) !important;
}

#us:hover,
#mx:hover,
#br:hover,
#es:hover {
  transition: 1s;
  fill: orange !important;
}

.estados {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  height: 70px;
}

.estados div {
  width: 100px;
  height: 10px;
  font-size: 12px;
}

.estados .espana {
  background: #33cc99;
}

.estados .brasil {
  background: #57cca9;
}

.estados .mexico {
  background: #1c3651;
}

.estados .usa {
  background: rgb(243, 218, 245);
}

.estados .espana:hover {
  background: #1c3651;
}

.estados .brasil:hover {
  background: #47b896;
}

.estados .mexico:hover {
  background: #4bb6b8;
}

.estados .usa:hover {
  background: rgb(209, 177, 211);
}

/* dedicatoria styles */
.dedicatoria {
  font-size: 25px;
  padding: 100px 100px;
  color: #4e595f;
}

/* CASO EXITTO IMAGENES  */
/* charts */
.Chart {
  margin-left: auto;
  margin-right: auto;
}

.socios,
.club {
  text-align: left;
  margin-left: 150px;
  margin-top: 80px;
}

.popup,
.popup2 {
  background: rgba(100, 100, 100, 0.5);
  position: fixed;
  display: none;
  z-index: 5000;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.popup,
.popup2 .wrapper {
  overflow-y: scroll;
  height: 600px;
  margin-left: 15%;
}
.popup img {
  width: 100%;
}
.popup2 img {
  width: 100%;
}
.popup,
.popup2 > div {
  max-width: 1000px;
  width: 70%;
  position: fixed;
  transform: translate(-50%, -50%);
  left: 37%;
  top: 50%;
  background: #fff;
  padding: 30px;
  z-index: 5001;
  text-align: center;
  border: 5px solid #9ad3de;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
}

.color-text {
  color: #9ad3de;
}

/* POP UP IMMAGE ON CLICK */

.css-popup-container,
.css-popup-container * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
.css-popup-container {
  font: normal 2em/2.2em 'Open Sans Condensed', sans-serif;
  height: auto;
  text-align: center;
  line-height: 30px;
}
.css-popup-container button {
  background: #9ad3de;
  border: 0;
  font-size: 18px;
  height: 100px;
  width: 300px;
}

.css-popup-container a:hover {
  text-decoration: none;
}
.css-popup-container strong {
  background: #666;
  color: #fff;
  font-weight: bold;
  padding: 0.1em 0.2em;
  text-transform: none;
}
.css-popup-container hr {
  background: transparent;
  border-color: transparent;
  margin: 10px 0 0;
  padding: 0;
}

.css-popup-container .css-popup {
  display: none;
  margin: auto;
  padding: 0.25em;
  position: relative;
}

.css-popup-container .css-popup img {
  width: 200%;
  z-index: 1;
  margin-top: -50%;
}

.img-gafete {
  width: 200%;
  margin-left: -100%;
  z-index: 1;
  margin-top: -50%;
}
.css-popup-container .css-popup .fa-window-close-o {
  z-index: 1;
}
.css-popup-container .css-popup .fa-window-close-o:after {
  border-radius: 100%;
  color: #333;
  content: 'X';
  display: block;
  float: right;
  margin-bottom: 370%;
  font: bold 1em/1.6 Verdana;
  padding: 0 0.4em;
  position: relative;
}
.css-popup-container .css-popup .fa-window-close-o:hover:after {
  background: #ddd;
}

.css-popup-container button:active,
.css-popup-container button:focus {
  border: 0;
  outline: 0;
  opacity: 0.5;
}
.css-popup-container button:focus ~ .css-popup,
.css-popup-container button:active ~ .css-popup {
  display: block;
  outline: 0;
}

/* PROCESO STYLES */

.proceso {
  padding: 0 100px;
  font-size: 16px;
}

.procesoimg img {
  display: block;
  margin: auto;
  width: 40%;
  height: auto;
}
/*=================================================================
   REQUISITOS BLOC 
==================================================================*/
.datos {
  font-size: 20px;
  margin: 50px 0;
}

.proceso-article {
  background: #000;
}

.process-block {
  background-color: #f6f8f7;
  padding: 60px 20px;
  border-left: 1px solid rgba(236, 239, 241, 0.07);
  border-top: 1px solid rgba(236, 239, 241, 0.07);
  margin-bottom: 40px;
  box-shadow: 2px 2px 5px rgba(44, 44, 44, 0.3), 13px 13px 0 rgba(255, 255, 255, 0.7);
  height: 400px;
  margin-top: 70px;
  width: 292.5px;
  margin-left: auto;
  margin-right: auto;
}

.process-block img {
  width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.process-block h3 {
  padding-top: 50px;
}

.requisitos {
  margin-top: 100px;
}

.requisitos-block {
  margin-bottom: 100px;
}

.requisitos-block .req-block {
  border-right: dotted 3px gray;
  /* padding-left: 9.5px; */
  border-left: dotted 0px red;
}

.requisitos-block .row article:nth-child(1) .req-block {
  border-left: dotted 3px gray;
}

.req-block {
  padding-top: 60px;
  border-left: 1px solid rgba(236, 239, 241, 0.07);
  border-top: 1px solid rgba(236, 239, 241, 0.07);
  margin-bottom: 40px;
  margin-left: -25px;
}

.blockr {
  font-size: 20px;
  border: var(--main-color) 15px solid;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin-top: -50px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.req-block span {
  display: block;
  margin-top: 50%;
  color: var(--main-color);
  font-size: 80px;
  font-weight: bolder;
  transform: translateY(-50%);
  font-family: 'Nunito', sans-serif;
}

.req-block h3 {
  font-size: 23px !important;
  margin-top: 50px;
  /* margin-left: 0px; */
}

.impact-block .row h3 {
  font-size: 24px !important;
}

/*=================================================================
   MODAL SLIDE 
==================================================================*/
/* .modal-images {
  display: flex;
  justify-content: center;
}

.modal-images a {
  cursor: pointer;
}

.modal-images a img {
  object-fit: cover;
  width: 230px;
  height: 230px;
}

.modal {
  margin-top: 50px;
  width: 100%;
  justify-content: center;
}

.modal .modal-dialog {
  margin-left: 15%;
}

.modal .modal-dialog .modal-content {
  background: none;
  border: none;
}

.modal img {
  width: 1000px;
  margin-bottom: 50px;
}

.data-modal {
  margin: 0 70px;
}

.data-modal p:hover {
  color: #57cbcc;
} */

/*=================================================================
   DEDICATORIA 
==================================================================*/
.dedicatoria {
  width: 100%;
  font-size: 25px;
  padding: 50px 100px;
  color: #4e595f;
  margin-top: 15px;
}

.dedicatoria .square {
  margin: auto;
  width: 50%;
  height: 100%;
  border: solid 0.5px #57cbcc;
  padding: 100px 150px;
}

.dedicatoria p:nth-child(2) {
  text-align: right;
}

.agradecimiento {
  margin-top: 50px;
  margin-bottom: -150px;
}

/* MEDIA QUERIES */
@media (min-width: 1024px) and (max-width: 1366px) {
  .instituciones img {
    height: 50px;
    width: auto;
  }

  .inst img {
    width: 80px;
    height: auto;
  }

  #club img {
    width: 170px;
    height: auto;
    padding-top: 15px;
  }
  /* TEAM MEMBERS MEDIA */
  #proceso .container-fluid .row {
    width: 100% !important;
    margin: 0 !important  ;
  }
  .req-block {
    padding-top: 60px;
    border-left: 1px solid rgba(236, 239, 241, 0.07);
    border-top: 1px solid rgba(236, 239, 241, 0.07);
    margin-bottom: 40px;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }
  .blockr {
    border: #aee32e 10px solid;
    width: 110px;
    height: 110px;
  }
  .req-block h3 {
    font-size: 15px;
    margin-top: 25px;
  }
  .req-block span {
    font-size: 60px;
  }
  .requisitos-block .req-block {
    border-right: dotted 3px gray;
    border-left: dotted 3px gray;
    /* padding-left: 19.5px; */
    padding-right: 30px;
  }
  /* dedicatoria large format*/
  .dedicatoria {
    padding: 50px;
  }
  .dedicatoria .square {
    width: 50%;
    padding: 15px;
  }
  .contentreport h2 {
    margin-left: 0;
  }
  .beneficiados .block h1 {
    font-size: 30px !important;
  }
  .beneficiados .block p {
    font-size: 18px !important;
  }
  .beneficiados .block h2 {
    font-size: 25px !important;
    margin-bottom: 20px;
  }
  .administrable .row {
    padding: 0;
  }
  .requisitos .req-block .blockr {
    margin-left: 0;
  }
  .requisitos .req-block h3 {
    margin-left: 0px;
  }
  /* dashboard gafete large */
  .modal .modal-dialog .modal-content img {
    width: 150%;
  }
  .modal .modal-dialog {
    margin-left: 15%;
    margin-top: 10%;
  }
  /* Alianzas large */
  .alianzas .container {
    width: 100%;
  }
  .alianza {
    height: 350px;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    margin-top: -50px;
    margin-bottom: -180px;
  }

  .donadora {
    padding-top: 35px;
    margin-left: 0px;
  }
  .microsoft {
    margin-left: 0px;
  }
  .img-alianza {
    width: 150px;
  }

  .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .socios,
  .club {
    margin-left: 0;
  }
  /*=================================================================
  IMPACTO MEDIA LARGE
==================================================================*/
  .impact-block {
    width: 100%;
  }
  .impact-block .row {
    padding: 0;
  }
  .process-block {
    width: 100%;
  }

  #soluciones .col-md-3 {
    width: 50%;
  }

  .service-block h3 {
    font-size: 20px !important;
  }

  .administrables {
    height: 600px;
  }
  .administrable h3 {
    font-size: 30px !important;
  }
  .administrables h3 {
    margin-top: 25px;
    font-size: 15px !important;
  }
  /* requisitos large */
  .req-block {
    padding-top: 60px;
    border-left: 1px solid rgba(236, 239, 241, 0.07);
    border-top: 1px solid rgba(236, 239, 241, 0.07);
    margin-bottom: 40px;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }
  .blockr {
    border: #aee32e 10px solid;
    margin-left: 15px !important;
    width: 110px;
    height: 110px;
  }
  .requisitos-block .row {
    padding: 0;
  }
  .req-block {
    width: 100%;
    height: 225px;
  }
  .req-block h3 {
    font-size: 15px !important;
    margin-top: 25px;
    margin-left: 25px !important;
  }
  .req-block span {
    font-size: 60px;
  }
  .requisitos-block .req-block {
    border-right: dotted 3px gray;
    border-left: dotted 3px gray;
  }
}

/* 
/*=tablet==================================================================================================================================*/

@media (min-width: 480px) and (max-width: 768px) {
  .navbar {
    font-size: 8px;
  }
  .navbar-collapse {
    background: #ededed !important;
    padding: 0 !important;
  }

  .about .container {
    padding-top: 50px !important;
  }
  #mainpro {
    margin-left: 0px !important;
    text-align: center;
  }

  /* Alianzas */
  .alianza {
    margin-bottom: 10px !important;
  }
  .row {
    padding: 0;
  }

  .section {
    padding: 50px 0;
  }
  .exitoimg img {
    width: 400px;
  }
  /* requisitos ipad */
  .req-block {
    margin-top: 100px;
    margin-bottom: 0px;
  }
  .blockr {
    border: #aee32e 10px solid;
    width: 110px;
    height: 110px;
  }
  .req-block h3 {
    font-size: 15px !important;
    margin-top: 25px;
  }
  .req-block span {
    font-size: 60px;
  }
  .requisitos-block .req-block {
    border-right: dotted 3px gray;
    border-left: dotted 3px gray;
    padding-left: 19.5px;
    padding-right: 20px;
  }

  /*=================================================================
  About us section misión visión MEDIA IPAD
==================================================================*/
  .blocksz {
    height: 410px;
  }

  .about .content h3 {
    font-size: 12pt !important;
    margin-top: -20px;
  }

  #mainpro {
    margin-left: 33%;
  }

  .box {
    width: 30px;
    height: 80px;
  }

  .letter {
    font-size: 1cm;
    line-height: 50px;
    position: relative;
    left: 0;
    transition: 0s;
    font-family: 'Barlow Condensed', sans-serif;
  }
  /*=================================================================
  team Members MEDIA IPAD
==================================================================*/
  .member-photo {
    text-align: center;
  }
  .member-photo img {
    display: inline-block;
  }
  /*=================================================================
  IMPACTO MEDIA IPAD
==================================================================*/

  .process-block {
    width: 100%;
  }

  #soluciones .col-md-3 {
    width: 50%;
  }

  .service-block h3 {
    font-size: 20px !important;
  }

  .administrables {
    height: 700px;
  }
  /*=================================================================
  PROCESO MEDIA IPAD
==================================================================*/
  .alianzas .container {
    width: 100%;
  }
  .alianza {
    height: 350px;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    margin-top: -100px;
    margin-bottom: -180px;
  }

  .donadora {
    padding-top: 20px;
    margin-left: 0px;
  }
  .microsoft {
    margin-left: 0px;
  }
  .img-alianza {
    width: 150px;
  }

  .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .procesoimg img {
    display: unset;
    width: 100%;
  }
  /* BANNER MEDIA IPAD*/
  .banner {
    width: 102%;
  }
  .contentreport {
    width: 100%;
    margin-top: 50px;
  }
  .contentreport h2 {
    padding: 0 150px;
    margin-left: 0%;
    font-size: 20px;
  }
  .contentreport p {
    padding: 0 150px;
    font-size: 20px;
  }
  /* map ipad media */
  .estados div {
    width: 60px;
    height: 6px;
    font-size: 7px;
  }
  .estados div p {
    font-size: 7px;
  }
  .css-popup-container {
    text-align: center !important;
  }

  .css-popup-container button {
    margin: 10px 0;
  }

  .css-popup-container .css-popup .img-exito {
    width: 100%;
    margin-top: -120px;
  }

  .css-popup-container .css-popup .img-gafete {
    width: 100%;
    margin-left: 0%;
    margin-top: -30px;
  }
  .css-popup-container .css-popup .fa-window-close-o:after {
    margin-bottom: 300%;
    font: bold 0.4em/1.6 Verdana;
    padding: 0 0.4em;
  }
  .requisitos-block .req-block {
    border-right: dotted 3px gray;
    padding-left: 9.5px;
    border-left: dotted 3px gray;
  }
  #proceso .container-fluid .row {
    width: 100% !important;
    margin: 0 !important  ;
  }
  .beneficiados .block h2 {
    font-size: 20px !important;
  }
  .beneficiados .block p {
    font-size: 14px;
  }
  .beneficiados .block h1 {
    margin-top: 30px;
    font-size: 30px !important;
  }

  .beneficiados .block span {
    font-size: 15px !important;
  }
  .socios,
  .club {
    margin-left: 0px;
  }
  /* dashboard gafete ipad */
  .modal .modal-dialog .modal-content img {
    width: 110%;
  }
  .modal .modal-dialog {
    margin-left: 15%;
    margin-top: 15%;
  }

  /* dedicatoria ipad */
  .dedicatoria {
    padding: 50px;
  }
  .dedicatoria .square {
    width: 60%;
    padding: 15px;
  }
}
/*===MOBILE================================================================================================================================*/
@media screen and (max-width: 480px) {
  .row {
    padding: 0;
  }

  .navbar-collapse {
    background: #ededed !important;
    padding: 0 !important;
  }
  .section {
    padding: 50px 0;
  }

  .exitoimg img {
    width: 300px;
  }
  /*=================================================================
  About us section misión visión MEDIA MOBILE
==================================================================*/

  .about .container {
    width: 100%;
  }

  .blocksz {
    margin-left: 40px;
    margin-right: auto;
    height: 440px;
  }

  .beneficiados .block h1 {
    color: var(--main-color) !important;
  }

  .about .content h3 {
    font-size: 10pt !important;
    margin-top: -30px;
  }
  #mainpro {
    margin-left: 13%;
  }

  .box {
    width: 30px;
    height: 80px;
  }

  .letter {
    font-size: 1cm;
    line-height: 50px;
    font-family: 'Barlow Condensed', sans-serif;
  }

  /*=================================================================
  PROCESO MEDIA MOBILE
==================================================================*/

  .procesoimg img {
    display: unset;
    width: 100%;
  }

  .alianza {
    height: 100px; /* equals max image height */
    width: 100%;
  }
  .img-alianza {
    width: 100px;
  }
  .soluciones_ {
    padding: 25px;
  }
  /* BANNER MEDIA MOBILE*/
  .banner {
    width: 102%;
  }
  .contentreport {
    width: 100%;
    margin-top: 50px;
  }
  .contentreport h2 {
    padding: 0;
    margin-left: 5%;
    font-size: 16px !important;
  }
  .contentreport p {
    padding: 0 10px;
    font-size: 10px;
  }
  .probonologo {
    width: 300px;
  }
  /* DASHBOARD GAFETE MOBILE */
  .modal-images img {
    width: 100px !important ;
    height: 100px !important;
  }
  .modal .modal-dialog {
    margin-left: 10%;
    margin-top: 30%;
  }
  .modal .modal-dialog .modal-content img {
    width: 90%;
  }
  /* map media mobile */
  .estados div {
    font-size: 6px;
  }
  .estados div p {
    font-size: 6px;
  }
  /* dedicatoria mobile */
  .dedicatoria {
    font-size: 16px;
    padding: 50px;
  }
  .dedicatoria .square {
    width: 100%;
    padding: 15px;
  }
  /* beneficiados nuestra solucion mobile */
  .css-popup-container .css-popup img {
    width: 100%;
  }

  .css-popup-container .css-popup .img-gafete {
    margin-left: 0%;
    margin-top: -30px;
  }
  .css-popup-container .css-popup .fa-window-close-o:after {
    margin-bottom: 330%;
    font: bold 0.4em/1.6 Verdana;
    padding: 0 0.4em;
  }
  .beneficiados .block h1 {
    margin-top: 50px;
    font-size: 30px !important;
  }
  .beneficiados .block h2 {
    font-size: 20px !important;
    margin-bottom: 25px;
  }
  .beneficiados .block p {
    font-size: 20px;
    margin-bottom: -10px;
  }
  .beneficiados .block span {
    font-size: 25px !important;
  }
  .beneficiados .blocksz {
    margin-left: 0;
  }

  #casos-exito p {
    font-size: 10px;
  }
  /* chart */
  .socios,
  .club {
    margin-left: 0px;
    margin-bottom: -20px;
  }

  .requisitos-block .req-block {
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-left: dotted gray 3px;
  }

  .req-block h3 {
    font-size: 15px !important;
  }

  .administrable h3 {
    font-size: 30px !important;
  }

  .administrables {
    height: 580px;
  }
  .administrables h3 {
    font-size: 20px !important;
  }
  .alianza {
    margin-top: -30px;
    margin-bottom: 100px;
    justify-content: center;
  }
  .microsoft {
    margin-left: 0;
  }
  .donadora {
    margin-left: 0;
    padding-top: 40px;
  }
  .description,
  .description p {
    font-size: 20px !important;
  }
}

.frame-1 {
  height: 1100px;
  width: 2194px;
  background-color: #ffffff;
}
.group-1-4-5 {
  height: 671px;
  width: 1453px;
}
.rectangle-1-3-0 {
  height: 671px;
  width: 399px;
  background-color: #c4c4c4;
}
.rectangle-1-3-2 {
  height: 671px;
  width: 399px;
  background-color: #c4c4c4;
}

.rectangle-1-3-1 {
  height: 671px;
  width: 399px;
  background-color: #c4c4c4;
}
