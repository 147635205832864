@media (min-width: 320px) and (max-width: 480px) {
  .solutions-sub h3 {
    text-align: left;
    padding: 0;
  }
  input.btn.btn-secondary {
    padding: 50px 0;
  }
  label.btn.btn-secondary {
    font-size: 8px !important;
    padding: 10px 0;
  }
  .requisitos-title h3 {
    padding-left: 18px;
  }

  #instituciones-apoyadas {
    width: 20px;
    height: 20px;
    background-color: transparent;
    margin-top: -150px;
    position: absolute;
  }
  #nuestro-equipo {
    width: 20px;
    height: 20px;
    background-color: transparent;
    margin-top: -40px;
    position: absolute;
  }
  #caso-de-exito {
    width: 20px;
    height: 20px;
    background-color: transparent;
    margin-top: -25px;
    position: absolute;
  }
  #nosotros {
    width: 20px;
    height: 20px;
    background-color: transparent;
    margin-top: 20px;
    position: absolute;
  }
  #proceso {
    width: 20px;
    height: 20px;
    background-color: transparent;
    margin-top: -40px;
    position: absolute;
  }
  .metric-buttons p {
    margin-left: 10px;
  }
  button.slick-arrow.slick-next:before {
    margin-left: 0px !important;
  }

  .softteklogo {
    margin-left: 0px;
    width: 200px;
  }
  .about .mision-vision {
    margin-left: 10px;
  }
  .about .mision-vision .mision {
    padding-right: 50px;
    height: 250px;
  }
  .about .mision-vision .mision,
  .about .mision-vision .vision {
    margin: 0;
  }
  .about .mision-vision .vision {
    height: 320px;
  }
  .modal img {
    margin-top: -20%;
  }
  .donadora {
    margin-left: 0px;
    width: 200px;
  }
  .microsoft {
    margin-left: 0px;
    width: 200px;
  }

  .about .titles span {
    margin-top: 20px;
  }
  /* navbar */
  .navbar-blue {
    padding-top: 10px;
  }
  .navbar-blue .navbar-collapse {
    background-color: #1c3651 !important;
  }

  .about {
    background-color: white;
  }
  /* video content */
  .contentreport h2 {
    margin-top: -15%;
    margin-left: 0%;
  }
  .contentreport p {
    font-size: 20px;
  }
  .about .video-probono {
    width: 0;
    /* margin-left: -40px; */
    margin-top: 40px;
    margin-bottom: -50px;
  }

  .about .video-probono video {
    width: 300px;
  }

  /* general titles */
  .titles {
    margin-top: 0px;
    margin-bottom: 0;
  }
  .general_description {
    padding: 0;
  }
  .titles h3 {
    font-size: 50px !important;
    -webkit-text-stroke: 2px;
  }
  .titles span {
    font-size: 18px !important;
    margin-top: 6.5%;
  }
  canvas.tsparticles-canvas-el {
    width: 100vw !important;
    margin-left: 10px !important;
  }
  /* about */
  .about .mision-vision p {
    font-size: 22px;
  }
  .about .mision-vision span {
    font-size: 19px;
  }
  .about .mision-vision .vision {
    margin-top: 50px;
    padding-right: 50px;
    margin-bottom: 50px;
  }
  .about-container .row {
    flex-direction: column;
  }
  .about .about-container {
    padding: 0 20px;
    box-shadow: 0px 10px 0px 0 rgb(65 65 65 / 6%);
  }
  .about .about-container .titles {
    padding-top: 30px;
  }
  .about-us-description {
    margin-top: 30px;
  }
  .title-two {
    padding: 0;
  }
  .title-two h3 {
    padding: 0;
  }
  .subtitle h3 {
    padding: 0;
  }

  /* process */
  .process-description {
    padding: 0 10px;
  }
  .process-buttons .btn-group {
    display: flex;
    padding: 0 10px;
  }
  .modelobtn,
  .solutionsbtn {
    padding-top: 25px !important;
  }
  .processbtn,
  .solutionbtn {
    padding-top: 23px !important;
  }

  .process-buttons .btn-group label {
    color: #000;
    font-size: 19px !important;
  }
  .process-buttons .btn-group button {
    font-size: 9px;
  }
  .btn-secondary {
    padding: 0;
  }
  .process-description p {
    padding: 0 0px;
  }
  .requirements {
    display: flex;
    flex-direction: column;
  }
  /* impact card */
  .title-impact h3 {
    font-size: 18px;
  }
  .row.solutions-description .col-md-2.col-sm-6.col-xs-12 {
    width: 100% !important;
    padding: 0;
  }

  .success_images {
    padding: 0;
  }

  .success_about p {
    font-size: 19px !important;
  }
  .success_images .genre_graphic {
    padding: 0;
  }
  .success_images .genre_graphic img {
    padding: 0;
  }

  .modal.show .modal-dialog {
    margin: auto;
  }

  .modal-body .success_dashboard,
  .modal-body .success_gafete {
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .row.solutions-description {
    padding: 0px 0 80px 0px !important;
  }

  .impact-container .impact-card article {
    margin-bottom: 50px;
  }
  .impact-container hr {
    display: none;
  }

  .container-work-model hr {
    display: none;
  }
  .how-we-work-container {
    box-shadow: 0px 10px 0px 0 rgb(65 65 65 / 6%);
  }

  /* reinvent */
  .reinvent_container-style {
    padding: 0;
    box-shadow: 0px 10px 0px 0 rgb(65 65 65 / 6%);
  }
  .reinvent-card p {
    margin-top: 20px;
  }
  .reinvent-card_container .reinvent-card {
    margin-bottom: 30px;
    width: 80%;
  }
  .reinvent-card_container {
    padding: 0;
  }

  .container-work-model .title-two {
    padding: 0 10px;
  }
  .container-work-model .general_description {
    padding: 0;
  }
  .reinvent_container-style .soluciones_ {
    padding: 0;
  }
  .reinvent_container-style .soluciones_ .general_description {
    padding: 0;
  }
  .solutions-description h3 {
    font-size: 50px;
  }
  .reinvent_container-style {
    margin-top: 50px;
  }
  .solutions-description p {
    font-size: 18px;
  }
  .reinvent-container {
    margin-top: 0px !important;
  }
  /* alianzas */
  .alianza {
    margin-top: -50px;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .ods-container {
    margin-top: 300px;
  }
  .alliance-ods_container .deco-alli {
    z-index: -99;
    width: 250px;
    left: -100px;
    top: 500px;
  }
  .green-title h3 {
    font-size: 25px;
    margin-top: 0px;
  }
  .soluciones_ p {
    line-height: 25px;
    padding: 0;
  }
  /* dedicatoria */
  .tribute-description_container {
    padding: 0 90px 0 90px;
  }
  .tribute-title h3 {
    font-size: 40px;
    margin-top: 30px;
    margin-bottom: -30px;
  }
  .tribute-description p {
    font-size: 14px !important;
    margin-bottom: -30px;
    padding: 0 20px !important;
  }
  /* voluntarios */
  .volunteers_contain .section-title p {
    padding: 0 15px;
  }

  .row {
    margin-right: 0;
  }
  .volunteers_dscp .number {
    font-size: 150px !important;
  }
  .volunteers_dscp .title {
    font-size: 64px !important;
  }

  .volunteers_dscp .dscp {
    margin-top: -35px;
    font-size: 22px !important;
  }
  .container-work-model .row {
    padding: 0;
  }
  .volunteer_container {
    display: inline-block;
    padding: 0 50px;
  }
  .volunteer_container .animation {
    padding: 0;
    height: 50%;
    margin-left: -50px;
  }
  .our-team-container {
    padding: 0 20px;
    box-shadow: 0px 10px 0px 0 rgb(65 65 65 / 6%);
  }
  .our-team_description p {
    padding: 0 !important;
    text-align: left;
  }
  .our-solutions_container p {
    margin-left: -38px;
  }
  .our-solutions_container .col-md-2 {
    margin-top: 30px;
  }
  /*=================================================================
   VOLUNTEERS PROFILE PICTURES
  ==================================================================*/
  #voluntarios {
    margin-top: 150px;
  }

  #voluntarios .title {
    margin-top: 0;
  }
  #voluntarios .dscp {
    margin-top: 0px;
  }

  .volunteers_img {
    position: absolute;
    margin-top: -280px;
  }
  #vltr0 {
    position: relative;
    left: 0px;
    top: -500px;
    width: 39.8px;
    height: 39.8px;
    text-align: center;
  }
  #vltr0 img {
    width: 39.8px;
    height: 39.8px;
    object-fit: cover;
  }
  #vltr1 {
    position: relative;
    left: 50px;
    top: -400px;
    width: 38px;
    height: 38px;
    text-align: center;
  }
  #vltr1 img {
    width: 38px;
    height: 38px;
    object-fit: cover;
  }
  #vltr2 {
    position: relative;
    left: 10px;
    top: -400px;
    width: 52.6px;
    height: 52.6px;
    text-align: center;
  }
  #vltr2 img {
    width: 52.6px;
    height: 52.6px;
    object-fit: cover;
  }
  #vltr3 {
    position: relative;
    left: 200px;
    top: -790px;
    width: 70px;
    height: 70px;
    text-align: center;
  }
  #vltr3 img {
    width: 70px;
    height: 70px;
    object-fit: cover;
  }

  #vltr4 {
    position: relative;
    left: 150px;
    top: -530px;
    width: 50px;
    height: 50px;
    text-align: center;
  }
  #vltr4 img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
  #vltr5 {
    position: relative;
    left: 80px;
    top: -610px;
    width: 69.8px;
    height: 69.8px;
    text-align: center;
    float: right;
  }
  #vltr5 img {
    width: 69.8px;
    height: 69.8px;
    object-fit: cover;
  }

  #vltr6 {
    position: relative;
    left: 150px;
    top: -880px;
    width: 78px;
    height: 78px;
    text-align: center;
    float: right;
  }
  #vltr6 img {
    width: 78px;
    height: 78px;
    object-fit: cover;
  }
  #vltr7 {
    position: relative;
    left: 0px;
    top: -850px;
    width: 42.6px;
    height: 42.6px;
    text-align: center;
    float: right;
  }
  #vltr7 img {
    width: 42.6px;
    height: 42.6px;
    object-fit: cover;
  }
  #vltr8 {
    position: relative;
    left: -50px;
    top: -850px;
    width: 50px;
    height: 50px;
    text-align: center;
    float: right;
  }
  #vltr8 img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
  #vltr9 {
    position: relative;
    left: 320px;
    top: -750px;
    width: 105px;
    height: 45px;
    text-align: center;
    float: right;
  }
  #vltr9 img {
    width: 105px;
    height: 105px;
    object-fit: cover;
  }

  #svg1 {
    position: relative;
    left: -180px;
    top: -800px;
    width: 120px;
    height: 120px;
    text-align: center;
  }
  #svg2 {
    display: none;
  }
  #svg2 img {
    display: none;
  }

  /*=================================================================
   INSTITUCIONES MOBILE
  ==================================================================*/
  .institutions {
    margin-top: -150px !important;
  }
  #viraventos {
    position: relative;
    left: -80px !important;
    top: -640px !important;
  }
  #educo {
    position: relative;
    left: 30px !important;
    top: -650px !important;
  }

  #alianza {
    position: relative;
    left: -80px !important;
    top: -550px !important;
  }

  #fundemex {
    position: relative;
    left: -60px !important;
    top: -620px !important;
  }
  #villas {
    position: relative;
    left: -50px !important;
    top: -980px !important;
  }
  #anspac {
    position: relative;
    left: 50px !important;
    top: -950px !important;
  }

  #fundacion {
    position: relative;
    left: 115px !important;
    top: -1390px !important;
  }
  #renace {
    position: relative;
    left: 105px !important;
    top: -980px !important;
  }

  #clubninos {
    position: relative;
    left: 105px !important;
    top: -850px !important;
  }
  #cruzrosa {
    position: relative;
    left: 300px !important;
    top: -1700px !important;
  }
  #t {
    position: relative;
    left: 110px !important;
    top: -1350px !important;
  }

  #acuerdos {
    position: relative;
    left: 280px !important;
    top: -1400px !important;
  }
  #canica {
    position: relative;
    left: 290px !important;
    top: -1550px !important;
  }

  /*=================================================================
   METRICAS MOBILE
  ==================================================================*/

  .metric-buttons a {
    margin-top: 30px;
  }
  .metric-buttons p {
    margin-top: 33px;
    margin-left: -75px;
  }

  .metric-buttons .buttons {
    margin-left: -15%;
    margin-top: 40px !important;
  }
  .metric-buttons input {
    width: 105px;
    border: none;
    background-color: transparent;
    touch-action: none !important;
  }
  .tribute .col-md-6.col-sm-6.col-xs-12:nth-child(1) {
    padding: 0;
  }
  .logo-probono {
    left: 30px;
  }
  .navbar-light .navbar-toggler {
    margin-right: 0px;
  }
  .tribute-date h3 {
    font-size: 14px;
    text-align: right;
    margin-top: 20px;
    margin-bottom: 15px;
    margin-right: -35px;
    padding-left: 50px;
  }

  .tribute-description_items .general_description {
    padding: 0;
    margin: 0;
  }
  .metrics_places h3 {
    font-size: 24px;
  }
  .metrics_container img {
    margin-top: 20px;
  }
  .metrics_container {
    box-shadow: 0px 10px 0px 0 rgb(65 65 65 / 6%);
  }
  /*=================================================================
   CASO DE EXITO MOBILE
  ==================================================================*/
  .success_about img:nth-child(1) {
    padding: 0;
  }
  .success_container {
    box-shadow: 0px 10px 0px 0 rgb(65 65 65 / 6%);
  }

  .about .titles {
    margin-top: 50px;
  }

  .success_about .dashboard p {
    font-size: 19px !important;
    margin-bottom: 8px;
    padding: 0;
  }

  .dashboard .success_dashboard {
    margin-top: 25px;
  }
  .success_about {
    padding: 0;
  }
  .success_about h3 {
    padding: 0 !important;
  }
  .success_about .general_description {
    padding: 0 !important;
  }

  .cnynnl {
    width: 80%;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .footer .container .img-fluid {
    max-width: 100%;
  }
}

#viraventos {
  position: relative;
  left: -80px;
  top: -640px;
}
#educo {
  position: relative;
  left: 30px;
  top: -650px;
}

#alianza {
  position: relative;
  left: -80px;
  top: -550px;
}

#fundemex {
  position: relative;
  left: -60px;
  top: -320px;
}
#villas {
  position: relative;
  left: 350px;
  top: -380px;
}
#anspac {
  position: relative;
  left: 850px;
  top: -950px;
}

#fundacion {
  position: relative;
  left: 950px;
  top: -1210px;
}
#renace {
  position: relative;
  left: 800px;
  top: -980px;
}

#clubninos {
  position: relative;
  left: 900px;
  top: -850px;
}
#cruzrosa {
  position: relative;
  left: 800px;
  top: -1520px;
}
#t {
  position: relative;
  left: 50px;
  top: -950px;
}

#acuerdos {
  position: relative;
  left: 880px;
  top: -950px;
}
#canica {
  position: relative;
  left: 980px;
  top: -1550px;
}

@media (-webkit-min-device-pixel-ratio: 3) {
  .metric-buttons {
    color: #1c3651 !important;
  }
  .metric-buttons input.form-control {
    border: none;
    font-size: 33px;
    color: #1c3651 !important;
    background-color: transparent;
    touch-action: none !important;
  }
  .modal-body .success_dashboard,
  .modal-body .success_gafete {
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .metric-buttons a {
    margin-top: 0px;
  }
  .metric-buttons input {
    color: #1c3651 !important;
  }
  .metric-buttons p {
    margin-top: 33px !important;
    margin-top: 0px;
  }
  .metric-buttons {
    margin-left: 44% !important;
  }
  .metric-buttons a {
    margin-top: 31px !important;
  }
  #nuestro-equipo {
    width: 20px;
    height: 20px;
    background-color: transparent;
    margin-top: -40px;
    position: absolute;
  }
  #nosotros {
    width: 20px;
    height: 20px;
    background-color: transparent;
    margin-top: 20px;
    position: absolute;
  }
  #caso-de-exito {
    width: 20px;
    height: 20px;
    background-color: transparent;
    margin-top: -25px;
    position: absolute;
  }
  #proceso {
    width: 20px;
    height: 20px;
    background-color: transparent;
    margin-top: -40px;
    position: absolute;
  }
  #instituciones-apoyadas {
    width: 20px;
    height: 20px;
    background-color: transparent;
    margin-top: -150px;
    position: absolute;
  }
}
